@import './components/common/Common.scss';
@import './components/file/File.scss';
@import './components/assets-liabilities/AssetsLiabilities.scss';
@import url('https://fonts.googleapis.com/css?family=Overpass&display=swap');

#root {
  height: 100%;
}

body {
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* min-width: 1200px; */
}
