.socialButton-customizable {
  border-radius: 2px;
  height: 50px;
  margin-bottom: 15px;
  padding: 1px;
  text-align: left;
  width: 100%;
}

.socialButton-customizable.google-button {
  background: #4285f4;
  color: #fff;
  font-family: Roboto, 'Helvetica Neue', Arial, sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
}

.socialButton-customizable.facebook-button {
  background-color: #4267b2;
  color: white;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.socialButton-customizable.google-button .social-logo {
  background: #FFF;
  border-radius: 2px;
}

.socialButton-customizable .social-logo {
  display: inline-block;
  height: 100%;
  margin-right: 12px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
}

