.assets-liabilities-search-form {
  margin-top: 16px;
}

.ant-calendar-picker {
  width: 100%;
}

.assets-liabilities-search-form .ant-btn + .ant-btn {
  margin-left: 8px;
}

.asset-liability-actions .anticon + .anticon {
  margin-left: 6px;
}

.linked-document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linked-document-item:hover {
  cursor: pointer;
}
.linked-document-item .anticon-delete {
  display: none;
}
.linked-document-item:hover .anticon-delete {
  display: inline-block;
}

.custom-tabs {
  .ant-tabs-nav-scroll {
    text-align: right;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.custom-tabs .ant-page-header-heading-sub-title {
  margin: 7px 0;
}

.table-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-flow: column;

  @include responsive(MD) {
    align-items: center;
    flex-flow: row;
  }

  .search-asset-liability-input {
    width: 100%;

    @include responsive(MD) {
      width: 320px;
    }
  }

  .group-by-selection {
    margin-top: 10px;
    flex-grow: 1;
    min-width: 180px;

    @include responsive(MD) {
      margin-top: 0px;
      margin-left: 16px;
    }
  }

  .actions-group {
    margin-top: 10px;
    display: flex;
    width: 100%;

    .ant-btn {
      flex-grow: 1;
    }

    .ant-btn + .ant-btn {
      margin-left: 8px;
    }

    @include responsive(MD) {
      margin-top: 0px;
      width: auto;
    }
  }
}

.pre-area {
  white-space: pre-wrap;
}

.asset-liability-title {
  display: inline-block;

  .main-title {
    margin-left: 8px;

    @include line-clamp(3);
    word-break: break-word;

    @include responsive(SM) {
      margin-left: 14px;
    }
  }
}

.balance-sheet-title {
  min-width: 190px;
}

.exclude-networth {
  &:after {
    content: '*';
    padding-left: 4px;
  }
}

.chart-color-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @include responsive(MD) {
    width: 200px;
  }

  @include responsive(SM) {
    width: 165px;
  }
}
